import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { WithChildren } from 'common/types'
import { formaDJRCyrillicMicroFontFamily } from 'components/designSystem/shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'

export const OrderedItem: FC<{} & WithChildren> = ({ children }) => {
  return <OrderedListItem>{children}</OrderedListItem>
}

export const orderListItemStyles = `
  width: 100%;
  counter-increment: custom-counter;
  position: relative;
  display: flex;
  margin-top: var(--spacing-16px);
  align-items: center;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
  font-family: ${formaDJRCyrillicMicroFontFamily};

  &:first-child {
    margin-top: 0;
  }

  &::before {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: baseline;
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: var(--spacing-12px);
    content: counter(custom-counter) '';
    color: var(--pearl-on-pearl);
    font-weight: 500;
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M1.61856 22.3285C5.79473 33.7081 17.5802 34.0183 25.1095 28.5517C36.13 20.5504 33.8099 1.288 19.0193 0.102622C4.22867 -1.08276 -3.60166 8.10392 1.61856 22.3285Z" fill="%23FFDED0"/></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`

const OrderedListItem = styled.li`
  ${orderListItemStyles}
`
